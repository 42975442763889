import { useMediaQuery } from "react-responsive";
import styles from "./Header.module.scss";
import Logo from "../../assets/images/logo.svg";
import telegramLogo from "../../assets/images/telegram.svg";
import whatsappLogo from "../../assets/images/whatsapp.svg";
import youtubeLogo from "../../assets/images/youtube_logo.svg";
import dzenLogo from "../../assets/images/zen_logo.svg";
import { Link, NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { AiOutlinePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineMenu } from "react-icons/ai";
import NavMenu from "../NavMenu/NavMenu";
import { navLinks } from "../../data";
import burgerStyles from "./burgerStyles";
import Button from "../Button";
import { useState } from "react";
import Modal from "react-modal";
import FeedbackForm from "@components/FeedbackForm";

Modal.setAppElement("#root");

export default function Header() {
  const is790 = useMediaQuery({ query: "(min-width: 790px)" });
  const is820 = useMediaQuery({ query: "(min-width: 820px)" });
  const is560 = useMediaQuery({ query: "(min-width: 560px)" });

  const [menuOpened, setMenuOpened] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <div className={styles.headMail}>
            <a
              href="mailto:info@teh-nadzor.pro"
              className={`${styles.contactLink} ${styles.mail}`}
            >
              <AiOutlineMail className={styles.icon} />
              <div className={styles.text}>info@teh-nadzor.pro</div>
            </a>
          </div>
          <div className={styles.headPhone}>
            <a
              href="tel:+79152455955"
              className={`${styles.contactLink} ${styles.phone}`}
            >
              <AiOutlinePhone className={`${styles.icon} ${styles.phone}`} />
              <div className={styles.text}>+7 (915) 245-59-55</div>
            </a>
          </div>

          {!is560 && (
            <div className={styles.contactLinkGroup}>
              <a
                href="https://t.me/Tehnadzorpro"
                className={`${styles.contactLink} ${styles.telegram}`}
              >
                <img
                  src={telegramLogo}
                  className={`${styles.icon} ${styles.telegram}`}
                />
              </a>
              <a
                href="https://wa.me/79152455955?text=%D0%97%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0"
                className={`${styles.contactLink} ${styles.whatsapp}`}
              >
                <img
                  src={whatsappLogo}
                  className={`${styles.icon} ${styles.whatsapp}`}
                />
              </a>
              <a
                href="https://dzen.ru/id/5ee52a8d790ceb6048923b53"
                target="_blank"
                className={`${styles.contactLink} ${styles.dzen}`}
              >
                <img
                  src={dzenLogo}
                  className={`${styles.icon} ${styles.dzen}`}
                />
              </a>
              <a
                href="https://youtube.com/@brickhome_stroycontrol"
                target="_blank"
                className={`${styles.contactLink} ${styles.youtube}`}
              >
                <img
                  src={youtubeLogo}
                  className={`${styles.icon} ${styles.youtube}`}
                />
              </a>
            </div>
          )}
        </div>
        <div className={styles.topRight}>
          {is790 && (
            <a
              href="tel:+79152455955"
              className={`${styles.contactLink} ${styles.phone}`}
            >
              <AiOutlinePhone className={styles.icon} />
              <div className={styles.text}>+7 (915) 245-59-55</div>
            </a>
          )}
          <Button
            className={styles.order_btn}
            theme="accent"
            handler={() => {
              setModalOpen(true);
            }}
          >
            Заказать услугу
          </Button>
          {is560 && (
            <>
              <a
                href="https://t.me/Tehnadzorpro"
                className={`${styles.contactLink} ${styles.telegram}`}
              >
                <img
                  src={telegramLogo}
                  className={`${styles.icon} ${styles.telegram}`}
                />
              </a>
              <a
                href="https://wa.me/79152455955?text=%D0%97%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0"
                className={`${styles.contactLink} ${styles.whatsapp}`}
              >
                <img
                  src={whatsappLogo}
                  className={`${styles.icon} ${styles.whatsapp}`}
                />
              </a>

              <a
                href="https://dzen.ru/id/5ee52a8d790ceb6048923b53"
                target="_blank"
                className={`${styles.contactLink} ${styles.dzen}`}
              >
                <img
                  src={dzenLogo}
                  className={`${styles.icon} ${styles.dzen}`}
                />
              </a>
              <a
                href="https://youtube.com/@brickhome_stroycontrol"
                target="_blank"
                className={`${styles.contactLink} ${styles.youtube}`}
              >
                <img
                  src={youtubeLogo}
                  className={`${styles.icon} ${styles.youtube}`}
                />
              </a>
            </>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <Link to={"/"} className={styles.logo}>
          <img src={Logo} className={styles.logoIcon} alt="Кирпичный дом" />
        </Link>
        <NavMenu />
        {!is820 && (
          <AiOutlineMenu
            className={styles.burgerBtn}
            onClick={() => setMenuOpened(true)}
          />
        )}
        {!is820 && (
          <Menu
            right
            disableAutoFocus
            width={200}
            styles={burgerStyles}
            isOpen={menuOpened}
            onStateChange={() => setMenuOpened(menuOpened)}
            customBurgerIcon={false}
            onOpen={() => setMenuOpened(true)}
            onClose={() => setMenuOpened(false)}
          >
            {navLinks.map((item) => {
              return (
                <NavLink
                  key={item.routeName}
                  to={item.routeName}
                  onClick={() => setMenuOpened(false)}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.navLink} ${styles.active}`
                      : styles.navLink
                  }
                >
                  {item?.icon}
                  {item.title !== "home" && item.title}
                </NavLink>
              );
            })}
          </Menu>
        )}
      </div>
      <Modal
        isOpen={modalOpen}
        className={styles.modalRoot}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          <button
            type="button"
            className={styles.modalCloseBtn}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            &#x274C;
          </button>
          <FeedbackForm />
        </div>
      </Modal>
    </header>
  );
}
